import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import { Navigate } from "react-router-dom";
import ScrollToTop from "../customHooks/useScrollTop";
import Loader from "../components/utils/loader";
import { RouteNavLoader } from "../root";

const Root = lazy(() => import("../root"));
const Dashboard = lazy(() =>
  lazyRetry(() => import("../views/dashboard/dashboard"))
);

//category Routes
const CategoryManager = lazy(() =>
  lazyRetry(() => import("../views/categoryManager/category"))
);
const Categories = lazy(() =>
  lazyRetry(() => import("../views/categories/categories"))
);

const AddCategory = lazy(() =>
  lazyRetry(() => import("../views/categories/addCategory"))
);

// Product Routes
const Products = lazy(() =>
  lazyRetry(() => import("../views/products/products"))
);
const AddProducts = lazy(() =>
  lazyRetry(() => import("../views/products/addProducts"))
);
const EditProducts = lazy(() =>
  lazyRetry(() => import("../views/products/editProduct"))
);

// Stock Manage Routes
const StockManageProducts = lazy(() =>
  lazyRetry(() => import("../views/stockManage/products"))
);

const ResellerStockManageProducts = lazy(() =>
  lazyRetry(() => import("../views/resellerStockManage/products"))
);

// Reseller Dashboard
const ResellerDashboard = lazy(() =>
  lazyRetry(() => import("../views/resellerDashboard/dashboard"))
);

// Reseller Product Pending Routes
const ResellerPendingProducts = lazy(() =>
  lazyRetry(() => import("../views/resellerProductPending/products"))
);
const ResellerPendingAddProducts = lazy(() =>
  lazyRetry(() => import("../views/resellerProducts/addProducts"))
);
const ResellerPendingEditProducts = lazy(() =>
  lazyRetry(() => import("../views/resellerProductPending/editProduct"))
);

// Reseller Product Routes
const ResellerProducts = lazy(() =>
  lazyRetry(() => import("../views/resellerProducts/products"))
);
const ResellerEditProducts = lazy(() =>
  lazyRetry(() => import("../views/resellerProducts/editProduct"))
);

// Reseller Variant Routes
const ResellerVariants = lazy(() =>
  lazyRetry(() => import("../views/resellerVariants/variants"))
);
const ResellerAddVariant = lazy(() =>
  lazyRetry(() => import("../views/resellerVariants/addVariant"))
);
const ResellerEditVariant = lazy(() =>
  lazyRetry(() => import("../views/resellerVariants/editVariant"))
);

// Variant Routes
const Variants = lazy(() =>
  lazyRetry(() => import("../views/variants/variants"))
);
const AddVariant = lazy(() =>
  lazyRetry(() => import("../views/variants/addVariant"))
);
const EditVariant = lazy(() =>
  lazyRetry(() => import("../views/variants/editVariant"))
);

// Subscriber Routes
const Subscribers = lazy(() =>
  lazyRetry(() => import("../views/subscription/subscribers"))
);

// Blog Post Routes
const Posts = lazy(() => lazyRetry(() => import("../views/blogs/posts")));
const AddPost = lazy(() => lazyRetry(() => import("../views/blogs/addPost")));
const EditPost = lazy(() => lazyRetry(() => import("../views/blogs/editPost")));

// Reviews Routes
const Reviews = lazy(() => lazyRetry(() => import("../views/reviews/reviews")));
const EditReviews = lazy(() =>
  lazyRetry(() => import("../views/reviews/editReview"))
);

// Order Routes
const Orders = lazy(() => lazyRetry(() => import("../views/orders/orders")));
const CompletedOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/completed_orders"))
);
const ProcessingOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/processing_orders"))
);
const PendingOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/pending_orders"))
);
const ReturnOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/return_orders"))
);
const Return_processingOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/return_processing_orders"))
);
const Return_completedOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/return_completed_orders"))
);
const CanceledOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/canceled_orders"))
);
const RefundOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/refund_orders"))
);
const NoRefundOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/no_refund_orders"))
);
const AddOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/addOrders"))
);
const EditOrders = lazy(() =>
  lazyRetry(() => import("../views/orders/editOrders"))
);

// Reseller Routes
const ResellerOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/orders"))
);
const ResellerCompletedOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/completed_orders"))
);
const ResellerProcessingOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/processing_orders"))
);
const ResellerPendingOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/pending_orders"))
);
const ResellerReturnOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/return_orders"))
);
const ResellerReturn_processingOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/return_processing_orders"))
);
const ResellerReturn_completedOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/return_completed_orders"))
);
const ResellerCanceledOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/canceled_orders"))
);
const ResellerRefundOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/refund_orders"))
);
const ResellerNoRefundOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/no_refund_orders"))
);
const ResellerEditOrders = lazy(() =>
  lazyRetry(() => import("../views/resellerOrder/editOrders"))
);

// Settings Routes
const GeneralSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/general"))
);

const StripeSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/stripe"))
);

const PaytabsSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/paytabs"))
);

const RecaptchaSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/recaptcha"))
);
const OAuthSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/oauth"))
);

const SMTPSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/smtp"))
);

const InvoiceSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/invoice"))
);

const WhatsappSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/whatsapp"))
);

const ShippingSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/shipping"))
);

const PickupSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/pickup"))
);

const ResellerShippingSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/resellerShipping"))
);

const ResellerPickupSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/resellerPickup"))
);

const ResellerGeneralSetting = lazy(() =>
  lazyRetry(() => import("../views/settings/resellerGeneral"))
);

// User Routes
const Users = lazy(() => lazyRetry(() => import("../views/users/users")));
const AddUser = lazy(() => lazyRetry(() => import("../views/users/addUser")));
const ViewUser = lazy(() => lazyRetry(() => import("../views/users/viewUser")));

// User Routes
const Customers = lazy(() =>
  lazyRetry(() => import("../views/customers/customers"))
);
const AddCustomer = lazy(() =>
  lazyRetry(() => import("../views/customers/addCustomer"))
);
const EditCustomer = lazy(() =>
  lazyRetry(() => import("../views/customers/editCustomer"))
);

// Referral Routes
const Referrals = lazy(() =>
  lazyRetry(() => import("../views/referrals/referrals"))
);
const Referral = lazy(() =>
  lazyRetry(() => import("../views/referrals/referral"))
);

// Reseller Routes
const Resellers = lazy(() =>
  lazyRetry(() => import("../views/resellers/resellers"))
);
const AddReseller = lazy(() =>
  lazyRetry(() => import("../views/resellers/addReseller"))
);
const EditReseller = lazy(() =>
  lazyRetry(() => import("../views/resellers/editReseller"))
);

// Account Routes
const Login = lazy(() => lazyRetry(() => import("../views/login/login")));
const Logout = lazy(() => lazyRetry(() => import("../views/logout/logout")));

const ResetAdmin = lazy(() =>
  lazyRetry(() => import("../views/reset/password"))
);

// Reseller Account Routes
const ResellerLogin = lazy(() =>
  lazyRetry(() => import("../views/resellerLogin/resellerLogin"))
);

// Discount Routes
const Discounts = lazy(() =>
  lazyRetry(() => import("../views/discounts/discounts"))
);
const AddDiscount = lazy(() =>
  lazyRetry(() => import("../views/discounts/addDiscount"))
);
const EditDiscount = lazy(() =>
  lazyRetry(() => import("../views/discounts/editDiscount"))
);

// Site Text Routes
const SiteContent = lazy(() =>
  lazyRetry(() => import("../views/siteContent/siteContent"))
);
const AddSiteContent = lazy(() =>
  lazyRetry(() => import("../views/siteContent/addSiteContent"))
);
const EditSiteContent = lazy(() =>
  lazyRetry(() => import("../views/siteContent/editSiteContent"))
);

// Site Meta Routes
const SiteMeta = lazy(() =>
  lazyRetry(() => import("../views/siteMeta/siteMeta"))
);
const AddSiteMeta = lazy(() =>
  lazyRetry(() => import("../views/siteMeta/addSiteMeta"))
);
const EditSiteMeta = lazy(() =>
  lazyRetry(() => import("../views/siteMeta/editSiteMeta"))
);

// Site Slider Routes
const SiteSlider = lazy(() =>
  lazyRetry(() => import("../views/siteSlider/siteSlider"))
);
const AddSliteSlider = lazy(() =>
  lazyRetry(() => import("../views/siteSlider/addSiteSlider"))
);
const EditSiteSlider = lazy(() =>
  lazyRetry(() => import("../views/siteSlider/editSiteSlider"))
);

// Site Header Routes
const SiteTopHeader = lazy(() =>
  lazyRetry(() => import("../views/siteHeader/siteTopHeader"))
);

// Site Social Media Routes
const SocialMedia = lazy(() =>
  lazyRetry(() => import("../views/siteSocialMedia/socialMedia"))
);

// Float Icon Routes
const FloatIcon = lazy(() =>
  lazyRetry(() => import("../views/floatIcon/floatIcon"))
);
const AddFloatIcon = lazy(() =>
  lazyRetry(() => import("../views/floatIcon/addFloatIcon"))
);
const EditFloatIcon = lazy(() =>
  lazyRetry(() => import("../views/floatIcon/editFloatIcon"))
);

// Notification Logs
const NotificationLogs = lazy(() =>
  lazyRetry(() => import("../views/notificationLogs/logs"))
);
const ResellerNotificationLogs = lazy(() =>
  lazyRetry(() => import("../views/resellerNotificationLogs/logs"))
);

// Recent View Logs
const RecentlyViewedList = lazy(() =>
  lazyRetry(() => import("../views/recentlyViewed/recentlyViewedList"))
);
const RecentlyViewed = lazy(() =>
  lazyRetry(() => import("../views/recentlyViewed/recenltyViewed"))
);

// Popup Message Routes
const PopupMessage = lazy(() =>
  lazyRetry(() => import("../views/popupMessage/popupMessage"))
);

// Purchase routes

const Purchase = lazy(() =>
  lazyRetry(() => import("../views/purchase/purchase"))
);

const PurchaseHistory = lazy(() =>
  lazyRetry(() => import("../views/purchase/purchaseHistory"))
);

const PurchaseInfo = lazy(() =>
  lazyRetry(() => import("../views/purchase/purchaseInfo"))
);

//sales routes

const Sales = lazy(() => lazyRetry(() => import("../views/sales/sales")));
const SalesInfo = lazy(() =>
  lazyRetry(() => import("../views/sales/salesInfo"))
);

// Reseller Purchase routes

const ResellerPurchase = lazy(() =>
  lazyRetry(() => import("../views/resellerPurchase/purchase"))
);

const ResellerPurchaseHistory = lazy(() =>
  lazyRetry(() => import("../views/resellerPurchase/purchaseHistory"))
);

const ResellerPurchaseInfo = lazy(() =>
  lazyRetry(() => import("../views/resellerPurchase/purchaseInfo"))
);

//sales routes

const ResellerSales = lazy(() =>
  lazyRetry(() => import("../views/resellerSales/sales"))
);
const ResellerSalesInfo = lazy(() =>
  lazyRetry(() => import("../views/resellerSales/salesInfo"))
);

// conditions
const Conditions = lazy(() =>
  lazyRetry(() => import("../views/conditions/conditions"))
);

// quality
const Quality = lazy(() => lazyRetry(() => import("../views/quality/quality")));

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Routes Initialization
const lazyRetry = function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
};

const routes = createBrowserRouter([
  {
    path: "admin",
    element: (
      <Suspense fallback={<RouteNavLoader />}>
        <ScrollToTop />
        <Root />
      </Suspense>
    ),
    loader: () =>
      import("../middlewares/authGuard").then(
        async (module) => await module.useAuthGuard("admin")
      ),
    children: [
      {
        path: "/admin",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "category",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <CategoryManager title="category" />
          </Suspense>
        ),
      },
      {
        path: "category/:sid/*",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Categories title="category List" />
          </Suspense>
        ),
      },
      {
        path: "category/:sid/add/*?",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddCategory title="category" />
          </Suspense>
        ),
      },
      {
        path: "products",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Products title="Products List" />
          </Suspense>
        ),
      },
      {
        path: "products/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddProducts title="Add Products" />
          </Suspense>
        ),
      },
      {
        path: "products/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditProducts title="Edit Product" />
          </Suspense>
        ),
      },
      {
        path: "stocks/manage",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <StockManageProducts title="Stock Manage" />
          </Suspense>
        ),
      },
      {
        path: "variants",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Variants title="Variants List" />
          </Suspense>
        ),
      },
      {
        path: "variants/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddVariant title="Add Variant" />
          </Suspense>
        ),
      },
      {
        path: "variants/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditVariant title="Edit Variant" />
          </Suspense>
        ),
      },
      {
        path: "discounts",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Discounts title="Discounts List" />
          </Suspense>
        ),
      },
      {
        path: "discounts/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddDiscount title="Add Discount" />
          </Suspense>
        ),
      },
      {
        path: "discounts/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditDiscount title="Edit Discount" />
          </Suspense>
        ),
      },
      {
        path: "site/content",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <SiteContent title="Site Text List" />
          </Suspense>
        ),
      },
      {
        path: "site/content/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddSiteContent title="Add Site Text" />
          </Suspense>
        ),
      },
      {
        path: "site/content/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditSiteContent title="Edit Site Text" />
          </Suspense>
        ),
      },
      {
        path: "site/meta",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <SiteMeta title="Site Meta List" />
          </Suspense>
        ),
      },
      {
        path: "site/meta/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddSiteMeta title="Add Site Meta" />
          </Suspense>
        ),
      },
      {
        path: "site/meta/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditSiteMeta title="Edit Site Meta" />
          </Suspense>
        ),
      },
      {
        path: "site/slider",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <SiteSlider title="Site Slider List" />
          </Suspense>
        ),
      },
      {
        path: "site/slider/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddSliteSlider title="Add Site Slider" />
          </Suspense>
        ),
      },
      {
        path: "site/slider/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditSiteSlider title="Edit Site Slider" />
          </Suspense>
        ),
      },
      {
        path: "site/top-header",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <SiteTopHeader title="Site Top Header Editor" />
          </Suspense>
        ),
      },
      {
        path: "site/social-media",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <SocialMedia title="Site Social Media" />
          </Suspense>
        ),
      },
      {
        path: "posts",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Posts title="Post List" />
          </Suspense>
        ),
      },
      {
        path: "posts/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddPost title="Add Post" />
          </Suspense>
        ),
      },
      {
        path: "posts/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditPost title="Edit Post" />
          </Suspense>
        ),
      },
      {
        path: "reviews",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Reviews title="Reviews List" />
          </Suspense>
        ),
      },
      {
        path: "reviews/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditReviews title="Edit Review" />
          </Suspense>
        ),
      },
      {
        path: "orders/all",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Orders title="Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/completed",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <CompletedOrders title="Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/processing",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ProcessingOrders title="Processing Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/return",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ReturnOrders title="Return Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/return_processing",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Return_processingOrders title="Return Processing Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/pending",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <PendingOrders title="Pending Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/return_completed",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Return_completedOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/canceled",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <CanceledOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/refunded",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <RefundOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/not_refunded",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <NoRefundOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddOrders title="Add Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditOrders title="Edit Order" />
          </Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Users title="Users List" />
          </Suspense>
        ),
      },
      {
        path: "users/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddUser title="Add User" />
          </Suspense>
        ),
      },
      {
        path: "users/view/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ViewUser title="View User" />
          </Suspense>
        ),
      },
      {
        path: "customers",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Customers title="Customers List" />
          </Suspense>
        ),
      },
      {
        path: "referrals",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Referrals title="Referrals List" />
          </Suspense>
        ),
      },
      {
        path: "referrals/:referral_code",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Referral title="Referrals List" />
          </Suspense>
        ),
      },
      {
        path: "customers/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddCustomer title="Add Customers" />
          </Suspense>
        ),
      },
      {
        path: "customers/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditCustomer title="Edit Customers" />
          </Suspense>
        ),
      },
      {
        path: "resellers",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Resellers title="Resellers List" />
          </Suspense>
        ),
      },
      {
        path: "resellers/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddReseller title="Add Resellers" />
          </Suspense>
        ),
      },
      {
        path: "resellers/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditReseller title="Edit Resellers" />
          </Suspense>
        ),
      },
      {
        path: "settings/general",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <GeneralSetting title="General Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/invoice",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <InvoiceSetting title="Invoice Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/stripe",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <StripeSetting title="Stripe Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/paytabs",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <PaytabsSetting title="Paytabs Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/recaptcha",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <RecaptchaSetting title="Recaptcha Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/oauth",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <OAuthSetting title="OAuth Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/smtp",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <SMTPSetting title="SMTP Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/whatsapp",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <WhatsappSetting title="Whatsapp Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/shipping",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ShippingSetting title="Shipping Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/pickup",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <PickupSetting title="Pickup Setting" />
          </Suspense>
        ),
      },
      {
        path: "notification/logs",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <NotificationLogs title="Notification Logs" />
          </Suspense>
        ),
      },
      {
        path: "product/recent/customer/logs",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <RecentlyViewedList title="Recently Viewed Logs" />
          </Suspense>
        ),
      },
      {
        path: "product/recent/customer/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <RecentlyViewed title="Recently Viewed Products" />
          </Suspense>
        ),
      },
      {
        path: "float/icons",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <FloatIcon title="Float Icon List" />
          </Suspense>
        ),
      },
      {
        path: "float/icons/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <AddFloatIcon title="Add Float Icon" />
          </Suspense>
        ),
      },
      {
        path: "float/icons/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <EditFloatIcon title="Edit Float Icon" />
          </Suspense>
        ),
      },
      {
        path: "popup/message",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <PopupMessage title="Popup Message" />
          </Suspense>
        ),
      },
      {
        path: "subscriber/email",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Subscribers title="Subscribers Email" />
          </Suspense>
        ),
      },
      {
        path: "purchase",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Purchase title="Purchases" />
          </Suspense>
        ),
      },
      {
        path: "purchase/history",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <PurchaseHistory title="Purchase History" />
          </Suspense>
        ),
      },
      {
        path: "purchase/info/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <PurchaseInfo title="Purchase Info" />
          </Suspense>
        ),
      },
      {
        path: "sales",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Sales title="Sales" />
          </Suspense>
        ),
      },
      {
        path: "sales/info/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <SalesInfo title="Sales Info" />
          </Suspense>
        ),
      },
      {
        path: "conditions",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Conditions title="Conditions" />
          </Suspense>
        ),
      },
      {
        path: "quality",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Quality title="Quality" />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "reseller",
    element: (
      <Suspense fallback={<RouteNavLoader />}>
        <ScrollToTop />
        <Root />
      </Suspense>
    ),
    loader: () =>
      import("../middlewares/authGuard").then(
        async (module) => await module.useAuthGuard("reseller")
      ),
    children: [
      {
        path: "notification/logs",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerNotificationLogs title="Notification Logs" />
          </Suspense>
        ),
      },
      {
        path: "/reseller",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerDashboard />
          </Suspense>
        ),
      },
      {
        path: "pending/products",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPendingProducts title="Pending Products" />
          </Suspense>
        ),
      },
      {
        path: "pending/products/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPendingEditProducts title="Edit Pending Product" />
          </Suspense>
        ),
      },
      {
        path: "products",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerProducts title="Product" />
          </Suspense>
        ),
      },
      {
        path: "products/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerEditProducts title="Edit Product" />
          </Suspense>
        ),
      },
      {
        path: "stocks/manage",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerStockManageProducts title="Stock Manage" />
          </Suspense>
        ),
      },
      {
        path: "products/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPendingAddProducts title="Add Products" />
          </Suspense>
        ),
      },
      {
        path: "variants",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerVariants title="Variants List" />
          </Suspense>
        ),
      },
      {
        path: "variants/add",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerAddVariant title="Add Variant" />
          </Suspense>
        ),
      },
      {
        path: "variants/edit/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerEditVariant title="Edit Variant" />
          </Suspense>
        ),
      },
      {
        path: "orders/all",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerOrders title="Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/completed",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerCompletedOrders title="Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/processing",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerProcessingOrders title="Processing Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/return",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerReturnOrders title="Return Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/return_processing",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerReturn_processingOrders title="Return Processing Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/pending",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPendingOrders title="Pending Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/return_completed",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerReturn_completedOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/canceled",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerCanceledOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/refunded",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerRefundOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/not_refunded",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerNoRefundOrders title="Return Completed Orders" />
          </Suspense>
        ),
      },
      {
        path: "orders/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerEditOrders title="Edit Order" />
          </Suspense>
        ),
      },
      {
        path: "settings/shipping",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerShippingSetting title="Shipping Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/pickup",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPickupSetting title="Pickup Setting" />
          </Suspense>
        ),
      },
      {
        path: "settings/general",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerGeneralSetting title="General Setting" />
          </Suspense>
        ),
      },
      {
        path: "purchase",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPurchase title="Purchases" />
          </Suspense>
        ),
      },
      {
        path: "purchase/history",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPurchaseHistory title="Purchase History" />
          </Suspense>
        ),
      },
      {
        path: "purchase/info/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerPurchaseInfo title="Purchase Info" />
          </Suspense>
        ),
      },
      {
        path: "sales",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerSales title="Sales" />
          </Suspense>
        ),
      },
      {
        path: "sales/info/:id",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerSalesInfo title="Sales Info" />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: "",
    children: [
      {
        path: "admin/login",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Login title="Login" />
          </Suspense>
        ),
        loader: () =>
          import("../views/login/login").then((module) => module.loginLoader()),
      },
      {
        path: "/reset/password",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResetAdmin title="Password Reset" />
          </Suspense>
        ),
        loader: () =>
          import("../views/login/login").then((module) => module.loginLoader()),
      },
      {
        path: "reseller/login",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <ResellerLogin title="Reseller Login" />
          </Suspense>
        ),
        loader: () =>
          import("../views/login/login").then((module) => module.loginLoader()),
      },
      {
        path: "logout",
        element: (
          <Suspense fallback={<RouteNavLoader />}>
            <Logout title="Logout" />
          </Suspense>
        ),
        loader: () =>
          import("../views/logout/logout").then((module) =>
            module.logoutLoader()
          ),
      },
    ],
  },
]);

export default routes;
